import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, CardActionArea, Container, Grid, Tooltip, Typography } from "@mui/material";
import { favoriteDog, getDogById, getDogSimpleById } from "../../slices/dogs";
import ActionButton from "../base/ActionButton";
import { useNavigate } from "react-router-dom";
import KeyValue from "../../types/keyValue";
import { MedicalServices, CalendarMonth, Female, Male, Favorite, AttachMoney, Info, Scale } from "@mui/icons-material";
import theme from "../../theme";
import Icon, { IconType } from "../base/Icon";
import Carousel from "../base/Carousel";
import FileVM from "../../types/fileVM";
import IconButton from "../base/IconButton";
import { getUserId } from "../../slices/users";
import { handleErrorToastState, handleSuccessToastState, setErrorMessage, setSuccessMessage } from "../../slices/toast";
import { setAuthModal, setAuthRoute } from "../../slices/auth";
import { startVideo, stopVideo } from "../../slices/file";
import ReactPlayer from "react-player";
import { getMyApplication, submitApplication } from "../../slices/applications";

interface Props {
  dogId: number;
}

const DogView: React.FC<Props> = (props) => {
  const { dogId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dog, dogSimple, status: dogStatus } = useSelector((store) => store.dogs);
  const { loggedInUser } = useSelector((store) => store.auth);
  const { myApplication, status } = useSelector((store) => store.applications);
  const { user } = useSelector((store) => store.users);
  const [viewImage, setViewImage] = useState("");
  const favorite = !!loggedInUser && !!user?.favoriteDogs?.find((d) => d?.id === dog?.id);
  const { videoPlaying } = useSelector((store) => store.file);
  const [video, setVideo] = useState<FileVM>();
  const [appliedForThisDog, setAppliedForThisDog] = useState(false);
  const [appliedForAnyDog, setAppliedForAnyDog] = useState(false);
  const [appliedForMaxDogs, setAppliedForMaxDogs] = useState(false);

  const [buttonDisabled, setButtonDisabled] = useState<"add" | "update" | "none">("none");

  useEffect(() => {
    dispatch(getDogById(dogId));
    dispatch(getDogSimpleById(dogId));
    if (!!loggedInUser) {
      dispatch(getUserId(loggedInUser.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (loggedInUser) {
      dispatch(getMyApplication()).then((result: any) => {
        if (result && result.payload) {
          setAppliedForAnyDog(true);

          if (result.payload.selectedDog && result.payload.otherDogs.length > 2) {
            setAppliedForMaxDogs(true);
          }

          if (result.payload.selectedDog && dogId === result.payload.selectedDog.id) {
            setAppliedForThisDog(true);
          } else {
            for (let i = 0; i < result.payload.otherDogs.length; i++) {
              if (
                result.payload.otherDogs &&
                result.payload.otherDogs.length &&
                dogId === result.payload.otherDogs[i].id
              ) {
                setAppliedForThisDog(true);
              }
            }
          }
        }
      });
    }
  }, [dispatch, loggedInUser]);

  const handleFavorite = (dogId: number) => {
    dispatch(favoriteDog(dogId)).then((results) => {
      if (results) {
        dispatch(getUserId(loggedInUser?.id!));
      }
    });
  };

  useEffect(() => {
    getMainPhoto(dog.photos);
  }, [dog]);

  const getMainPhoto = (photos: FileVM[]) => {
    let mainPhoto = photos?.find((p) => p.id === dog.mainPhotoId);
    if (!!mainPhoto) {
      setViewImage(mainPhoto.url);
    } else if (!!photos && photos.length > 0) {
      handleVideoClick(photos[0]);
    }
  };

  const setViewPhoto = (id: number) => {
    dog.photos.map((photo) => {
      if (photo.id === id) {
        setViewImage(photo.url);
      }
    });
  };

  const getDogGender = (gender: KeyValue) => {
    if (gender.id === 1) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <Male sx={{ color: theme.palette.primary.main }} />
          <Typography variant="body2">{gender.value}</Typography>
        </Box>
      );
    } else if (gender.id === 2) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <Female sx={{ color: theme.palette.primary.main }} />
          <Typography variant="body2">{gender.value}</Typography>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const handleVideoClick = (video: FileVM) => {
    setVideo(video);
    setViewPhoto(video.id!);
    dispatch(startVideo(video.id));
  };

  const handleImageClick = (id: number) => {
    setVideo(undefined);
    setViewPhoto(id);
    dispatch(stopVideo(0));
  };

  const addDog = () => {
    setButtonDisabled("add");
    if (myApplication?.status?.id === 9) {
      dispatch(submitApplication({ ...myApplication!, selectedDog: dogSimple })).then((result: any) => {
        if (result && result.payload && result.meta.requestStatus === "fulfilled") {
          dispatch(handleSuccessToastState(true));
          dispatch(setSuccessMessage("Application Submitted"));
          setButtonDisabled("none");
          navigate("/user-profile");
        }
      });
    } else {
      dispatch(submitApplication({ ...myApplication!, otherDogs: [...myApplication?.otherDogs!, dogSimple] })).then(
        (result: any) => {
          if (result && result.payload && result.meta.requestStatus === "fulfilled") {
            dispatch(handleSuccessToastState(true));
            dispatch(setSuccessMessage("Application Submitted"));
            setButtonDisabled("none");
            navigate("/user-profile");
          }
        }
      );
    }
  };

  const getDogBreeds = (primaryBreed: KeyValue, secondaryBreed?: KeyValue) => {
    if (secondaryBreed) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <Icon type={IconType.DogsFilled} color={theme.palette.primary.main} />
          <Typography variant="body2">
            {primaryBreed?.value} | {secondaryBreed.value}
          </Typography>
        </Box>
      );
    } else if (!secondaryBreed && primaryBreed) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
          <Icon type={IconType.DogsFilled} color={theme.palette.primary.main} />
          <Typography variant="body2">{primaryBreed.value}</Typography>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Container maxWidth="lg">
      {dogStatus === "loading" ? (
        <Typography variant="h1" textAlign={"center"}>
          Loading, please wait...
        </Typography>
      ) : (
        <>
          <Box sx={{ display: { xs: "block", md: "none" }, textAlign: "center", padding: 2 }}>
            <Typography variant="h1" fontWeight={"500"}>
              {dog.name}
            </Typography>
          </Box>
          <Grid container>
            <Grid item md={6} xs={12}>
              <Grid container direction={"column"}>
                <Grid item alignSelf={"center"}>
                  <CardActionArea disableRipple>
                    <Box
                      sx={{
                        position: "absolute",
                        top: 15,
                        right: 15,
                        color: favorite ? theme.palette.error.main : `${theme.palette.error.main}50`,
                        backgroundColor: theme.palette.primary.contrastText,
                        borderRadius: 25,
                      }}
                      onClick={(e) => {
                        if (loggedInUser) {
                          handleFavorite(dogId);
                        } else {
                          dispatch(handleErrorToastState(true));
                          dispatch(setErrorMessage("You must log in to add a dog to your favorites"));
                          dispatch(setAuthRoute("login"));
                          dispatch(setAuthModal(true));
                        }
                      }}
                    >
                      <IconButton icon={<Favorite />} label="Favorite" onClick={() => {}} size={"12px"} />
                    </Box>
                    {video && videoPlaying?.id === video.id ? (
                      <Box
                        sx={{
                          objectFit: "cover",
                          height: { lg: "516px", md: "350px", xs: "258px" },
                          width: { lg: "516px", md: "350px", xs: "258px" },
                          borderRadius: "25px",
                        }}
                      >
                        <ReactPlayer
                          onEnded={() => dispatch(stopVideo(video.id))}
                          height="100%"
                          width="100%"
                          playing
                          controls={true}
                          url={video.url}
                        />
                      </Box>
                    ) : viewImage ? (
                      <Box
                        component="img"
                        alt={`${dog.name} picture`}
                        src={dog.photos ? viewImage : ""}
                        sx={{
                          objectFit: "cover",
                          height: { lg: "516px", md: "350px", xs: "258px" },
                          width: { lg: "516px", md: "350px", xs: "258px" },
                          borderRadius: "25px",
                        }}
                      />
                    ) : (
                      <Box sx={{ marginTop: "-20px" }}>
                        <Typography>Picture coming soon!</Typography>
                      </Box>
                    )}
                  </CardActionArea>
                </Grid>
                <Grid item>
                  <Carousel
                    files={dog.photos}
                    filesPerView={4}
                    onPictureClick={handleImageClick}
                    onVideoClick={handleVideoClick}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container direction="column">
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: "20px",
                    display: {
                      xs: "none",
                      md: "block",
                    },
                  }}
                >
                  <Typography variant="h1">{dog.name}</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: "20px" }}>
                  <Grid container direction={"column"}>
                    {dog.medicalDog && (
                      <Grid item sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <MedicalServices color="primary" />
                        <Typography variant="body2">Medical Dog - I need a little extra care!</Typography>
                      </Grid>
                    )}
                    <Grid item>{getDogBreeds(dog.primaryBreed!, dog.secondaryBreed)}</Grid>
                    <Grid item>{dog.gender ? getDogGender(dog?.gender!) : ""}</Grid>
                    <Grid item sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                      <CalendarMonth sx={{ color: theme.palette.primary.main }} />
                      <Typography variant="body2">{dog.ageGroup?.value}</Typography>
                    </Grid>
                    {!!dog.weight && (
                      <Grid item sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <Scale sx={{ color: theme.palette.primary.main }} />
                        <Typography variant="body2">{dog.weight?.value}</Typography>
                      </Grid>
                    )}
                    {!!dog.adoptionFee && dog.adoptionFee > 0 && (
                      <Grid item sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
                        <AttachMoney sx={{ color: theme.palette.primary.main }} />
                        <Typography variant="body2">Adoption Fee: ${dog.adoptionFee}.00</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item mb={3} xs={2}>
                  <Typography variant="h2" sx={{ textAlign: "center" }}>
                    {dog.headline}
                  </Typography>
                </Grid>
                <Grid item mb={5} xs={2}>
                  <Typography variant="body1" sx={{ lineHeight: "18px" }}>
                    {dog.description}
                  </Typography>
                  <Typography variant="body1" sx={{ lineHeight: "18px" }} textAlign="center" justifySelf="center">
                    <br />
                    PA kennel license #5310 • 100 W Main St Lansdale, PA 19446
                  </Typography>
                </Grid>
                {dog.status?.id === 2 || dog.status?.id === 4 || dog.status?.id === 10 ? (
                  <Grid item alignSelf={"center"} xs={12}>
                    {appliedForThisDog &&
                      (myApplication?.status?.id === 1 ? (
                        <Grid
                          container
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Grid item sx={{ textAlign: "center", display: { md: "none", xs: "inline" } }}>
                            <Typography variant="caption">
                              You've already begun your application! Click here to finish applying for this dog.
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ActionButton
                              type="button"
                              text="Complete Application"
                              color="primary"
                              onClick={() => {
                                navigate(`/adopt/${dogId}`, { state: { loginForRedirect: true } });
                              }}
                            />
                            <Grid sx={{ display: { xs: "none", md: "inline" } }}>
                              <Tooltip title="You've already begun your application! Click this to finish applying for this dog.">
                                <Info fontSize="small" sx={{ marginLeft: "5px", marginTop: "-15px" }} />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container justifyContent={"center"} alignItems={"center"} spacing={4}>
                          <Grid item textAlign={"center"}>
                            <Typography>
                              Congratulations! You've already applied for this dog! A foster will look over your
                              application and reach out to you. You can still edit your application if anything about it
                              has changed.
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ActionButton
                              type="button"
                              text="Edit Application"
                              color="primary"
                              onClick={() => {
                                if (!loggedInUser) {
                                  navigate(`/adopt/${dogId}`, {
                                    state: { loginForRedirect: true },
                                  });
                                } else {
                                  navigate(`/adopt/${dogId}`);
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    {appliedForAnyDog &&
                      !appliedForThisDog &&
                      (myApplication?.status?.id === 1 ? (
                        <Grid
                          container
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Grid item sx={{ textAlign: "center", display: { md: "none", xs: "inline" } }}>
                            <Typography variant="caption">
                              {appliedForMaxDogs
                                ? "You have already added the maximum number of dogs to your application. No more dogs can be added at this time."
                                : "You've already begun your application! Click this to both add this dog to your application and continue it."}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ActionButton
                              disabled={appliedForMaxDogs}
                              type="button"
                              text="Complete Application"
                              color="primary"
                              onClick={() => {
                                navigate(`/adopt/${dogId}`);
                              }}
                            />
                            <Grid sx={{ display: { xs: "none", md: "inline" } }}>
                              <Tooltip
                                title={
                                  appliedForMaxDogs
                                    ? "You have already added the maximum number of dogs to your application. No more dogs can be added at this time."
                                    : "You've already begun your application! Click this to both add this dog to your application and continue it."
                                }
                              >
                                <Info fontSize="small" sx={{ marginLeft: "5px", marginTop: "-15px" }} />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          direction={"column"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Grid item sx={{ textAlign: "center", display: { md: "none", xs: "inline" } }}>
                            <Typography variant="caption">
                              {appliedForMaxDogs
                                ? "You have already added the maximum number of dogs to your application. Visit your profile page to make updates."
                                : "If your application has changed in any way, you can apply for this dog and update it at the same time by clicking this button."}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ActionButton
                              disabled={buttonDisabled === "update" || appliedForMaxDogs}
                              type="button"
                              text="Update Application"
                              color="primary"
                              onClick={() => {
                                navigate(`/adopt/${dogId}`);
                              }}
                            />
                            <Grid sx={{ display: { xs: "none", md: "inline" } }}>
                              <Tooltip
                                title={
                                  appliedForMaxDogs
                                    ? "You have already added the maximum number of dogs to your application. Visit your profile page to make updates."
                                    : "If your application has changed in any way, you can apply for this dog and update it at the same time by clicking this button."
                                }
                              >
                                <Info fontSize="small" sx={{ marginLeft: "5px", marginTop: "-15px" }} />
                              </Tooltip>
                            </Grid>
                          </Grid>
                          <Grid item sx={{ textAlign: "center", display: { md: "none", xs: "inline" } }}>
                            <Typography variant="caption">
                              {appliedForMaxDogs
                                ? "You have already added the maximum number of dogs to your application. No more dogs can be added at this time."
                                : "If your application hasn't changed, you can apply for this dog instantly by clicking this button."}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <ActionButton
                              disabled={buttonDisabled === "add" || appliedForMaxDogs}
                              type="button"
                              text="Add Dog To Application"
                              color="primary"
                              onClick={addDog}
                            />
                            <Grid sx={{ display: { xs: "none", md: "inline" } }}>
                              <Tooltip
                                title={
                                  appliedForMaxDogs
                                    ? "You have already added the maximum number of dogs to your application. No more dogs can be added at this time."
                                    : "If your application hasn't changed, you can apply for this dog instantly by clicking this button."
                                }
                              >
                                <Info fontSize="small" sx={{ marginLeft: "5px", marginTop: "-15px" }} />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    {(!appliedForAnyDog && !myApplication) || status === "loading" ? (
                      <Box sx={{ mb: 1 }}>
                        <ActionButton
                          type="button"
                          text="Adoption Application"
                          color="primary"
                          onClick={() => {
                            navigate(`/adopt/${dogId}`, { state: { loginForRedirect: true } });
                          }}
                        />
                      </Box>
                    ) : null}
                    <Box
                      onClick={() => navigate("/adoption-process")}
                      sx={{ cursor: "pointer", textDecoration: "underline", pt: 2, pb: 5, textAlign: "center" }}
                    >
                      <Typography variant="h2" sx={{ color: theme.palette.primary.main }}>
                        Our Adoption Process
                      </Typography>
                    </Box>
                  </Grid>
                ) : (
                  <Grid item alignSelf={"center"} xs={12}>
                    <Typography variant="h2">
                      This dog is no longer available for adoption. Please check out our other adoptable dogs!
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default DogView;
