import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "../../store";
import { Box, Checkbox, CircularProgress, Container, DialogActions, Typography } from "@mui/material";
import StyledTable from "../base/StyledTable";
import Moment from "react-moment";
import TableActions from "../base/TableActions";
import { combineDocuments, deleteFile, getFilesById, getOneFileById } from "../../slices/file";
import FileVM from "../../types/fileVM";
import Dropzone from "../base/Dropzone";
import UploadView from "./FileUploadView";
import Modal from "../base/Modal";
import Dialog from "../base/Dialog";
import ActionButton from "../base/ActionButton";
import checkUserRole, { UserRoles } from "../../helpers/checkUserRole";
import { handleSuccessToastState, setSuccessMessage } from "../../slices/toast";
import EmailDocumentView from "./EmailDocumentView";
import CheckboxButton from "../base/CheckboxButton";

interface Props {
  dogId: number;
}

const DogDocumentTable: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const { loggedInUser } = useSelector((store) => store.auth);
  const { newFile, status: fileStatus, fileSearch, downloadStatus } = useSelector((store) => store.file);

  const [uploadModal, setUploadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [fileList, setFileList] = useState<number[]>([]);

  const [fileUpload, setFileUpload] = useState<FileVM>({
    id: 0,
    fileType: { id: 3, value: "" },
    entityId: props.dogId,
    url: "",
    fileName: "",
  });

  const initialCheckedState = fileSearch.reduce((acc, file) => {
    acc[file.id!] = false;
    return acc;
  }, {} as { [key: number]: boolean });

  const [checked, setChecked] = useState(initialCheckedState);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getFiles();
  }, [dispatch]);

  const getFiles = () => {
    dispatch(getFilesById({ entityId: props.dogId, typeId: 3 }));
  };

  const handleSelectFile = (file: File[]) => {
    if (file && file[0] && file[0].name) {
      setFileUpload({ ...fileUpload, upload: file[0], fileName: file[0].name });
      setUploadModal(true);
    }
  };

  const handleEditFile = async (id: number) => {
    const result: any = await dispatch(getOneFileById(id));
    setFileUpload(result.payload);
    setUploadModal(true);
  };

  const handleDeleteModal = (id: number) => {
    setDeleteModal(true);
    dispatch(getOneFileById(id));
  };

  const handleDelete = (id: number) => {
    dispatch(deleteFile(id)).then(() => {
      getFiles();
      setDeleteModal(false);
    });
  };

  const cancelDelete = () => {
    setDeleteModal(false);
  };

  const close = () => {
    setFileUpload({ id: 0, fileType: { id: 3, value: "" }, entityId: props.dogId, url: "", fileName: "" });
    setUploadModal(false);
  };

  const closeEmail = () => {
    setEmailModal(false);
  };

  const closeEmailSuccess = () => {
    dispatch(handleSuccessToastState(true));
    dispatch(setSuccessMessage("Document successfully sent!"));
    setEmailModal(false);
    setFileList([]);
  };

  const handleEmail = async (id: number) => {
    await dispatch(getOneFileById(id));
    setEmailModal(true);
  };

  const handleDownload = (id: number) => {
    dispatch(getOneFileById(id)).then((result) => {
      let file = result.payload as FileVM;
      let a = document.createElement("a");
      a.href = file.url;
      a.download = file.fileName!;
      a.click();
      a.remove();
    });
  };

  const downloadDocuments = () => {
    let idFilters: string[] = [];

    for (const key in checked) {
      if (checked.hasOwnProperty(key)) {
        const value = checked[key];
        if (!!value) {
          idFilters.push(`id:${key}`);
        }
      }
    }

    dispatch(
      combineDocuments({
        pageNumber: 1,
        pageSize: 10,
        filters: idFilters,
      })
    );
  };

  const emailCombined = () => {
    let idFilters: number[] = [];

    for (const key in checked) {
      if (checked.hasOwnProperty(key)) {
        const value = checked[key];
        if (!!value) {
          idFilters.push(+key);
        }
      }
    }

    setFileList(idFilters);
    setEmailModal(true);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked: isChecked } = event.currentTarget;

    if (id === "selectAll") {
      setSelectAll(isChecked);
      setChecked((prevChecked) => {
        const updatedState: { [key: number]: boolean } = {};
        fileSearch.forEach((file) => {
          updatedState[file.id!] = isChecked;
        });
        return { ...prevChecked, ...updatedState };
      });
    } else {
      setChecked((prevChecked) => ({
        ...prevChecked,
        [parseInt(id)]: isChecked,
      }));
    }
  };

  const headers = ["Actions", "Date", "Name", "Type"];

  if (
    checkUserRole(loggedInUser!, UserRoles.Admin) ||
    checkUserRole(loggedInUser!, UserRoles.Document) ||
    checkUserRole(loggedInUser!, UserRoles.Foster)
  ) {
    headers.unshift("Download");
  }

  const rows =
    (!!props.dogId &&
      fileSearch
        .slice()
        .reverse()
        .map((file: FileVM) => {
          const hasDownload =
            checkUserRole(loggedInUser!, UserRoles.Admin) ||
            checkUserRole(loggedInUser!, UserRoles.Document) ||
            checkUserRole(loggedInUser!, UserRoles.Foster);

          let rowData = {
            actions: (
              <TableActions
                id={file.id!}
                disableView={!file.viewable}
                handleView={() => {
                  window.open(file.url, "_blank");
                }}
                handleDownload={handleDownload}
                handleEmailDocument={handleEmail}
                handleEdit={
                  checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Document)
                    ? handleEditFile
                    : undefined
                }
                handleDelete={
                  checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Document)
                    ? handleDeleteModal
                    : undefined
                }
              />
            ),
            uploaded: file.uploadedDate && <Moment format="MM/DD/YYYY">{file.uploadedDate!}</Moment>,
            name: file.fileName,
            type: file.documentType?.value,
          };

          return !hasDownload
            ? rowData
            : {
                download: (
                  <CheckboxButton
                    colorVariant="blue"
                    checked={checked[file.id!] || false}
                    onChange={handleChange}
                    id={file.id!.toString()}
                  />
                ),
                actions: (
                  <TableActions
                    id={file.id!}
                    disableView={!file.viewable}
                    handleView={() => {
                      window.open(file.url, "_blank");
                    }}
                    handleDownload={handleDownload}
                    handleEmailDocument={handleEmail}
                    handleEdit={
                      checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Document)
                        ? handleEditFile
                        : undefined
                    }
                    handleDelete={
                      checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Document)
                        ? handleDeleteModal
                        : undefined
                    }
                  />
                ),
                uploaded: file.uploadedDate && <Moment format="MM/DD/YYYY">{file.uploadedDate!}</Moment>,
                name: file.fileName,
                type: file.documentType?.value,
              };
        })) ||
    [];

  return !!props.dogId ? (
    <Box>
      {checkUserRole(loggedInUser!, UserRoles.Admin) || checkUserRole(loggedInUser!, UserRoles.Document) ? (
        <Box>
          <Dropzone
            onDrop={(file: File[]) => {
              handleSelectFile(file);
            }}
          />
        </Box>
      ) : null}
      {!!rows.length &&
        fileStatus !== "loading" &&
        (checkUserRole(loggedInUser!, UserRoles.Admin) ||
          checkUserRole(loggedInUser!, UserRoles.Document) ||
          checkUserRole(loggedInUser!, UserRoles.Foster)) && (
          <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "5px" }}>
            <Container>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  padding: 1,
                  gap: 2,
                }}
              >
                <Box>
                  <CheckboxButton
                    colorVariant="blue"
                    checked={selectAll}
                    onChange={handleChange}
                    id="selectAll"
                    label="Select All"
                  />
                </Box>
                <Box>
                  <ActionButton
                    text="Download Selected"
                    onClick={downloadDocuments}
                    type="button"
                    disabled={
                      downloadStatus === "loading" ||
                      Object.values(checked).filter((value) => value === true).length < 2
                    }
                    color="secondary"
                  />
                </Box>
                <Box>
                  <ActionButton
                    text="Email Selected"
                    onClick={emailCombined}
                    type="button"
                    disabled={
                      downloadStatus === "loading" ||
                      Object.values(checked).filter((value) => value === true).length < 2
                    }
                    color="primary"
                  />
                </Box>
              </Box>
            </Container>
          </Box>
        )}
      {fileStatus === "loading" ? (
        <Box display="flex" justifyContent="center" alignItems="center" paddingLeft="10px">
          <CircularProgress color="primary" size="50px" />
        </Box>
      ) : rows.length ? (
        <StyledTable
          headers={headers}
          rows={rows}
          defaultPaging
          paging={true}
          sizeVariant="small"
          title={<Typography variant="body2">Dog Documents</Typography>}
          totalRows={fileSearch.length}
        />
      ) : (
        <Typography textAlign={"center"}>No documents to display</Typography>
      )}
      <Modal open={uploadModal} onClose={close}>
        <UploadView
          onCancel={close}
          upload={fileUpload}
          afterSave={() => {
            getFiles();
            close();
          }}
          submittingStatus={fileStatus === "loading"}
          type="dog"
        />
      </Modal>
      <Dialog
        open={deleteModal}
        title={`Are you sure you want to delete ${
          newFile.fileName ? newFile.fileName : `this ${newFile.documentType?.value!}`
        }? This cannot be undone.`}
        warning
      >
        <Box>
          <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
            <ActionButton type="button" text="Cancel" color="secondary" onClick={cancelDelete} />
            <ActionButton
              type="button"
              text={"Delete"}
              disabled={fileStatus === "loading" ? true : false}
              color="error"
              onClick={() => {
                handleDelete(newFile.id!);
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Modal open={emailModal} onClose={closeEmail}>
        <EmailDocumentView
          onCancel={closeEmail}
          onSuccessClose={closeEmailSuccess}
          submittingStatus={fileStatus === "loading" || downloadStatus === "loading"}
          fileIds={fileList.length ? fileList : [newFile?.id!]}
        />
      </Modal>
    </Box>
  ) : (
    <></>
  );
};

export default DogDocumentTable;
