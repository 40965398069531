import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "../../store";
import {
  Box,
  Button,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
  Menu,
  Tooltip,
  useMediaQuery,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HouseIcon from "@mui/icons-material/House";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarItem from "../../types/navbarItem";
import { logout, setAuthRoute, setAuthModal } from "../../slices/auth";
import theme from "../../theme";
import DonateButton from "../base/DonateButton";
import Icon, { IconType } from "../base/Icon";
import PopoverMenu from "./PopoverMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Search from "../base/Search";
import { setSearchString } from "../../slices/search";
import { resetApplication } from "../../slices/applications";
import { resetUserValues } from "../../slices/users";
import adminReroute from "../../helpers/adminReroute";
import { Info } from "@mui/icons-material";

interface Props {}

const MainNavbar: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  const [logoutDisable, setLogoutDisable] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const { loggedInUser } = useSelector((state) => state.auth);
  const pathname = window.location.pathname;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const navbarItems: NavbarItem[] = [
    { title: "Home", path: "/home", icon: <HouseIcon sx={{ color: "primary.main" }} /> },
    { title: "About Us", path: "/about-us", icon: <InfoOutlinedIcon sx={{ color: "primary.main" }} /> },
    { title: "Events", path: "/events", icon: <Icon type={IconType.Events} color={theme.palette.primary.main} /> },
    {
      title: "Adoptable Dogs",
      path: "/dogs",
      icon: <Icon type={IconType.DogsFilled} color={theme.palette.primary.main} />,
    },
    {
      title: "Help Us",
      path: "/help-us",
      icon: <Icon type={IconType.Volunteer} color={theme.palette.primary.main} />,
    },
  ];

  const isActive = (path: string) => {
    const active = `${path}` === pathname;

    return active;
  };

  const handleLogin = () => {
    dispatch(setAuthRoute("login"));
    dispatch(setAuthModal(true));
  };

  const handleRegister = () => {
    dispatch(setAuthRoute("register"));
    dispatch(setAuthModal(true));
  };

  const handleProfileRoute = () => {
    setMobileMenuOpen(false);
    navigate("/user-profile", { state: { id: loggedInUser?.id } });
  };

  const handleLogout = async () => {
    setLogoutDisable(true);
    if (!!loggedInUser) {
      dispatch(logout());
      dispatch(resetApplication());
      dispatch(resetUserValues());
      navigate("/home");
      dispatch(setAuthModal(true));
      dispatch(setAuthRoute("login"));
      setMobileMenuOpen(false);
      setLogoutDisable(false);
    }
  };

  const handleMenuClose = () => {
    setUserMenuOpen(false);
  };

  return (
    <AppBar>
      {location.pathname === "/home" && (
        <Grid
          sx={{ textAlign: "center", cursor: "pointer", p: "5px", backgroundColor: theme.palette.secondary.main }}
          onClick={() => window.open("https://www.howloween5k.com/", "_blank")}
        >
          <Typography sx={{ typography: { sm: "h3", xs: "h5" }, color: theme.palette.primary.main }}>
            Howloween 5K & 1-Mile Dog Walk:{" "}
            <Box component={"span"} sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              Click here for details!
            </Box>
          </Typography>
        </Grid>
      )}
      {location.pathname === "/home" && (
        <Grid
          sx={{ textAlign: "center", cursor: "pointer", p: "5px" }}
          onClick={() => window.open("https://homeatlastswag.myshopify.com/", "_blank")}
        >
          <Typography sx={{ typography: { sm: "h3", xs: "h5" } }}>
            Show your support of Home at Last with our seasonal swag!{" "}
            <Box component={"span"} sx={{ textDecoration: "underline", fontWeight: "bold" }}>
              Click here to order!
            </Box>
          </Typography>
        </Grid>
      )}
      <Toolbar sx={{ minHeight: sm ? "90px" : "70px", backgroundColor: theme.palette.secondary.main }}>
        <Box sx={{ py: "4px", cursor: "pointer" }} onClick={() => navigate("/")}>
          <img height={sm ? 90 : 70} src="/static/images/hallogo.png" alt="logo" />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ py: "4px", display: { xs: "none", md: "flex" }, flexDirection: "row", alignItems: "center" }}>
          {navbarItems.map((navbarItem, index) => (
            <React.Fragment key={index}>
              <Button
                disableRipple
                onClick={() => {
                  navigate(navbarItem.path);
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: isActive(navbarItem.path) ? "700" : "400",
                    fontStyle: isActive(navbarItem.path) ? "bold" : "normal",
                    "&:hover": { fontWeight: "700", fontStyle: "bold" },
                  }}
                >
                  {navbarItem.title}
                </Typography>
              </Button>
              {index + 1 < navbarItems.length && (
                <Typography variant="h4" sx={{ color: theme.palette.primary.main, marginX: { md: 1, lg: 2 } }}>
                  |
                </Typography>
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {location.pathname === "/home" && (
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
            <Tooltip title="Search for dogs by their names and headline descriptions, and events by their names and descriptions.">
              <Info fontSize="medium" sx={{ marginLeft: "5px" }} />
            </Tooltip>
            <Search
              handleChange={setSearchTerm}
              secondary
              small
              label="Search"
              value={searchTerm}
              handleSubmit={async () => {
                await dispatch(setSearchString(searchTerm));
                await setSearchTerm("");
                await navigate("/search");
              }}
            />
          </Box>
        )}

        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <DonateButton size="large" />
        </Box>
        <Box
          onClick={loggedInUser ? () => setUserMenuOpen(!userMenuOpen) : handleLogin}
          ref={anchorRef}
          sx={{
            paddingRight: "0",
            marginLeft: "auto",
            marginTop: "5px",
            marginRight: -2,
            zIndex: 5000,
            display: { md: "flex", xs: "none" },
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "#fff",
              paddingTop: "10px",
              paddingBottom: "5px",
              height: "60px",
              width: "60px",
              marginBottom: "5px",
              marginLeft: "15px",
              ":hover": { cursor: "pointer" },
            }}
          >
            {loggedInUser ? <Icon type={IconType.DogsFilled} size="52px" /> : <Icon type={IconType.Key} size="52px" />}
          </Avatar>
        </Box>
        {loggedInUser && (
          <Box ref={popoverRef}>
            <Menu
              anchorEl={anchorRef.current}
              open={userMenuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{
                horizontal: "center",
                vertical: "bottom",
              }}
            >
              <PopoverMenu />
            </Menu>
          </Box>
        )}
        <IconButton
          edge="start"
          color="inherit"
          sx={{ mr: 2, display: { xs: "block", md: "none" } }}
          onClick={() => {
            setMobileMenuOpen(true);
          }}
        >
          <Icon type={IconType.Menu} color={theme.palette.primary.main} size="30px" />
        </IconButton>

        <Drawer
          anchor="right"
          open={mobileMenuOpen}
          onClose={() => {
            setMobileMenuOpen(false);
          }}
          sx={{
            "& .MuiPaper-root": {
              border: "none",
              backgroundColor: "transparent",
              paddingBottom: "25px",
            },
          }}
        >
          <Box
            sx={{
              p: 2,
              height: 1,
              backgroundColor: theme.palette.background.paper,
              width: "300px",
              paddingBottom: "90px",
            }}
          >
            <IconButton
              sx={{ mb: 2 }}
              onClick={() => {
                setMobileMenuOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>

            <Divider sx={{ mb: 2 }} />
            <Box sx={{ mb: 2, height: "100%", overflow: "scroll", paddingBottom: "25px" }}>
              {navbarItems.map((item, index) => {
                return (
                  <ListItemButton
                    onClick={() => {
                      navigate(item.path);
                      setMobileMenuOpen(false);
                    }}
                    key={index}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                );
              })}
              <ListItemButton
                onClick={() => {
                  navigate("/search");
                  setMobileMenuOpen(false);
                }}
              >
                <ListItemIcon>
                  <Icon type={IconType.Magnify} color={theme.palette.primary.main} />
                </ListItemIcon>
                <ListItemText primary={"Search"} />
              </ListItemButton>
              {loggedInUser?.roles?.length ? (
                <ListItemButton
                  onClick={() => {
                    navigate(adminReroute(loggedInUser!));
                    setMobileMenuOpen(false);
                  }}
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCog} color={theme.palette.primary.main} />
                  </ListItemIcon>
                  <ListItemText primary={"Admin Site"} />
                </ListItemButton>
              ) : null}
              {loggedInUser ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    bottom: "100px",
                    marginTop: "25px",
                  }}
                >
                  <Button variant="contained" size="small" sx={{ m: 1, width: 0.5 }} onClick={handleProfileRoute}>
                    Profile
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ m: 1, width: 0.5 }}
                    disabled={logoutDisable}
                    onClick={handleLogout}
                  >
                    Logout
                  </Button>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    bottom: "100px",
                  }}
                >
                  <Button variant="contained" size="small" sx={{ m: 1, width: 0.5 }} onClick={handleRegister}>
                    Register
                  </Button>
                  <Button variant="outlined" size="small" sx={{ m: 1, width: 0.5 }} onClick={handleLogin}>
                    Login
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default MainNavbar;
