import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import http from "../api/http";
import getSearchQueryString from "../helpers/getSearchQueryString";
import DogDetail from "../types/dogDetail";
import FileVM from "../types/fileVM";
import SearchParams from "../types/searchParams";
import SearchResults from "../types/searchResults";
import DogSimple from "../types/dogSimple";

interface DogsState {
  status: "idle" | "loading" | "failed";
  searchStatus: "idle" | "loading" | "failed";
  dog: DogDetail;
  dogSearch?: SearchResults;
  photo: FileVM;
  dogSimple: DogSimple;
}

const initialState: DogsState = {
  status: "idle",
  searchStatus: "idle",
  dog: {
    id: 0,
    name: "",
    alsoKnownAs: "",
    intakeDate: undefined,
    approximateBirthdate: undefined,
    headline: "",
    description: "",
    medicalDog: false,
    primaryBreed: undefined,
    gender: undefined,
    dogOrigin: undefined,
    ageGroup: { id: 0, value: "" },
    microchipNumber: "",
    adoptionFee: undefined,
    status: { id: 1, value: "On Hold" },
    subStatus: undefined,
    photos: [],
    documents: [],
    notes: [],
    coatColors: [],
    markings: "",
    paLisense: undefined,
    weight: undefined,
    alterStatus: undefined,
    alterDue: undefined,
    rabiesDue: undefined,
    dispositionDate: undefined,
    dogMother: undefined
  },
  photo: {
    fileType: {
      id: 1,
      value: "",
    },
    url: "",
  },
  dogSimple: {
    id: 0,
    name: "",
    headline: "",
    primaryBreed: { id: 0, value: "" },
    gender: { id: 0, value: "" },
    ageGroup: { id: 0, value: "" },
    image: "",
  },
};

export const getDogsSearch = createAsyncThunk<
  SearchResults,
  {
    search: SearchParams;
    includePhotos?: boolean;
    intakeStart?: Date;
    intakeEnd?: Date;
    statusStart?: Date;
    statusEnd?: Date;
  }
>("dogs/search", async ({ search, includePhotos, intakeStart, intakeEnd, statusStart, statusEnd }) => {
  let qs = getSearchQueryString(search);
  let photos = includePhotos === true;
  let date = "";

  function isValidDate(d: any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

  date += !!intakeStart && isValidDate(intakeStart) ? `&intakeStartDate=${intakeStart.toUTCString()}` : "";
  date += !!intakeEnd && isValidDate(intakeEnd) ? `&intakeEndDate=${intakeEnd.toUTCString()}` : "";
  date += !!statusStart && isValidDate(statusStart) ? `&statusStartDate=${statusStart.toUTCString()}` : "";
  date += !!statusEnd && isValidDate(statusEnd) ? `&statusEndDate=${statusEnd.toUTCString()}` : "";
  const response = await http.get<SearchResults>(`/dogs/search?${qs}&includePhotos=${photos}${date}`);
  return response.data;
});

export const postNewDog = createAsyncThunk<DogDetail, DogDetail>("/dogs/post", async (dog: DogDetail) => {
  const response = await http.post<DogDetail>(`/dogs`, dog);
  return response.data;
});

export const getDogById = createAsyncThunk<DogDetail, number>("/dogs/get/id", async (id: number) => {
  const response = await http.get<DogDetail>(`/dogs/${id}`);
  return response.data;
});

export const editDog = createAsyncThunk<DogDetail, {dog: DogDetail, archiveAdoption?: boolean}>("dogs/edit", async ({dog, archiveAdoption}) => {
  const response = await http.put<DogDetail>(`/dogs/${dog.id}?archiveAdoption=${archiveAdoption ? true : false}`, dog);
  return response.data;
});

export const favoriteDog = createAsyncThunk<null, number>("dogs/favorite", async (id: number) => {
  const response = await http.patch(`/dogs/${id}/favorite`);
  return response.data;
});

export const deleteDog = createAsyncThunk<DogDetail, number>("dogs/delete", async (id: number) => {
  const response = await http.delete<DogDetail>(`/dogs/${id}`);
  return response.data;
});

export const getDogSimpleById = createAsyncThunk<DogSimple, number>("dogs/get-simple/id", async (id: number) => {
  const response = await http.get<DogSimple>(`dogs/${id}/simple`);
  return response.data;
});

const dogsSlice = createSlice({
  name: "dogs",
  initialState,
  reducers: {
    resetDog(state) {
      state.dog = { ...initialState.dog };
    },
    resetSimpleDog(state) {
      state.dogSimple = { ...initialState.dogSimple };
    },
    setDog(state, action) {
      state.dog = action.payload;
    },
    clearDogSearch(state) {
      state.dogSearch = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDogsSearch.pending, (state) => {
        state.searchStatus = "loading";
      })
      .addCase(getDogsSearch.fulfilled, (state, action) => {
        state.searchStatus = "idle";
        state.dogSearch = action.payload;
      })
      .addCase(getDogsSearch.rejected, (state, action) => {
        state.searchStatus = "failed";
      })
      .addCase(getDogById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDogById.fulfilled, (state, action) => {
        state.status = "idle";
        state.dog = action.payload;
      })
      .addCase(getDogById.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(postNewDog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postNewDog.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(postNewDog.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(editDog.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editDog.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(editDog.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getDogSimpleById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDogSimpleById.fulfilled, (state, action) => {
        state.status = "idle";
        state.dogSimple = action.payload;
      })
      .addCase(getDogSimpleById.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { resetDog, setDog, clearDogSearch, resetSimpleDog } = dogsSlice.actions;

export default dogsSlice.reducer;
