import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { createRef, useState } from "react";
import ReactPlayer from "react-player";
import theme from "../../../theme";
import { KeyboardDoubleArrowDown } from "@mui/icons-material";

interface Props {
  useIsVisible: (ref: any) => boolean;
  eventRef: React.RefObject<HTMLDivElement>;
}

const VideoBanner: React.FC<Props> = (props) => {
  const { useIsVisible, eventRef } = props;
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const bannerRef = createRef<HTMLDivElement>();

  const handleDownClick = () => {
    bannerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleReady = () => {
    setIsReady(true);
  };

  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container sx={{ marginTop: { xs: "60px", sm: "40px" } }}>
      <Grid item xs={12}>
        <Box
          sx={{
            position: "fixed",
            bottom: 52,
            right: 15,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 25,
            display: useIsVisible(eventRef) ? "none" : { xs: "none", lg: "flex" },
            zIndex: 100,
            cursor: "pointer",
          }}
          onClick={handleDownClick}
        >
          <KeyboardDoubleArrowDown fontSize="large" />
        </Box>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "0",
            paddingTop: "56.25%",
            overflow: "hidden",
          }}
        >
          <ReactPlayer
            muted
            height="100%"
            width="100%"
            playsinline
            playing={true}
            loop
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              opacity: isReady || isPlaying ? 1 : 0,
              transition: "opacity 5s ease-in-out",
            }}
            url={"https://vimeo.com/877258708"}
            onPlay={() => setIsPlaying(true)}
            onBuffer={() => setIsPlaying(false)}
            onReady={handleReady}
          />

          <img
            src="/static/images/hallogo.png"
            alt="logo"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "80%",
              maxHeight: "80%",
              opacity: !isReady && !isPlaying ? 1 : 0,
              transition: "opacity 1s ease-in-out",
            }}
          />
        </Box>
        <Box ref={bannerRef} sx={{ position: "relative", bottom: 138 }} />
      </Grid>
    </Grid>
  );
};

export default VideoBanner;
